<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}

/deep/ .el-upload-dragger {
  height: 40px;
}
</style>

<template>
  <el-dialog top="4vh" v-if="m" :title="this.id == 0 ? '支付宝配置增加' : '支付宝配置修改'" :visible.sync="isShow"
             width="800px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
             custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <el-form size="medium" label-width="auto">
          <el-form-item label="商户名:">
            <el-input v-model="m.mchName" style="width: 500px"></el-input>
          </el-form-item>
          <el-form-item label="应用appId:">
            <el-input v-model="m.appid" style="width: 500px"></el-input>
          </el-form-item>


          <el-form-item label="是否使用证书:">
            <el-radio v-model="m.isCert" :label="0" @input="changePayType">证书</el-radio>
            <el-radio v-model="m.isCert" :label="1" @input="changePayType">密钥</el-radio>
          </el-form-item>

          <el-form-item label="应用私钥RSA2048:">
            <el-input :rows="5" type="textarea" v-model="m.privateKey" style="width: 500px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.isCert == 1 " label="alipayPublicKey_RSA2:">
            <el-input :rows="5" type="textarea" v-model="m.alipayPublicKey" style="width: 500px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.isCert == 0 " label="appCertPublicKey:">
            <el-input :rows="5" type="textarea" v-model="m.appPublicCertPath" style="width: 500px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.isCert == 0 " label="alipayCertPublicKey_RSA2:">
            <el-input :rows="5" type="textarea" v-model="m.alipayPublicCertPath" style="width: 500px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.isCert == 0 " label="alipayRootCert:">
            <el-input :rows="5" type="textarea" v-model="m.alipayRootCertPath" style="width: 500px"></el-input>
          </el-form-item>

          <el-form-item label="备注:">
            <el-input style="width: 500px" type="textarea" :rows="7" v-model="m.remarks">
            </el-input>
          </el-form-item>
          <el-form-item label="是否分账:"
                        v-if="m.isCert==0 && (m.payType==3 || m.payType==5 || m.payType==25|| m.payType==26)">
            <el-switch :value="m.profitShare" :active-value="1" :inactive-value="0" inactive-color="#ff4949"
                       active-text="分账"
                       inactive-text="不分账" @change="setProfitShare"></el-switch>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      isShow: false,
      id: 0,
      showType: 0,
      imgUrl: 'https://render.alipay.com/p/f/fd-ixpo7iia/index.html',
      m: {
        type: 4,
        host: '',
        mchName: '',
        appid: '',
        isCert: 1,
        payType: '0',
        limits: 5000,
        alipayUid: '',
        privateKey: '',
        alipayPublicKey: '',
        appPublicCertPath: '',
        alipayPublicCertPath: '',
        alipayRootCertPath: '',
        remarks: '',
        subject: '',
        profitShare: 0,
      },
    }
  },
  methods: {
    // 打开
    open: function (data, showType) {
      this.id = data.id
      this.isShow = true
      this.showType = showType
      if (this.id == 0 || data == 0) {
        // 如果是添加
        this.id = 0
      } else {
        // id 非 0 代表 是要修改 先查询一下，把旧值显示在input中
        this.m.mchName = data.mchName
        this.m.appid = data.appid
        this.m.isCert = data.isCert + ''
        this.m.type = data.type + ''
        this.m.limits = data.limits
        this.m.privateKey = data.privateKey
        this.m.alipayPublicKey = data.alipayPublicKey
        this.m.appPublicCertPath = data.appPublicCertPath
        this.m.alipayPublicCertPath = data.alipayPublicCertPath
        this.m.alipayRootCertPath = data.alipayRootCertPath
        this.m.remarks = data.remarks
        this.m.payType = data.payType + ''
        this.m.alipayUid = data.alipayUid
        this.m.host = data.host
        this.m.subject = data.subject
        this.m.profitShare = data.profitShare
      }
    },
    setProfitShare: function (data) {
      this.m.profitShare = data
    },
    changePayType: function (data) {
      console.log(data)
      this.m.profitShare = 0
    },
    // 表单验证
    submit_check: function () {
      return 'ok'
    },
    // 提交
    ok: function () {
      // 表单验证
      if (this.submit_check() != 'ok') {
        return
      }
      // 开始增加或修改
      let self = this
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      }
      if (this.id == 0) {
        // id == 0 为增加
        this.sa.ajax(
          '/v1/alipay/config/save',
          this.m,
          function () {
            self.sa.alert('添加成功', function () {
              self.$parent.f5() // 父视图刷新
              self.isShow = false
              self.claerData()
            })
          },
          defaultCfg,
        )
      } else {
        // id != 0 为修改
        this.sa.ajax(
          '/v1/alipay/config/edit/' + this.id,
          this.m,
          function () {
            self.sa.alert(
              '修改成功',
              function () {
                self.$parent.f5() // 父视图刷新
                self.isShow = false
                self.claerData()
              },
              defaultCfg,
            )
          },
          defaultCfg,
        )
      }
    },
    claerData () {
      Object.keys(this.m).forEach((key) => {
        this.m[key] = ''
      })
    },

  },
  created: function () {
    this.imgUrl = this.sa.cfg.api_url +
      '/v1/index/enQrcode?url=https://render.alipay.com/p/f/fd-ixpo7iia/index.html'
  },
}
</script>
